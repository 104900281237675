// src/components/AdminDashboard.js
import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
          <div>
            <h2 className="text-lg font-bold">Total Customer</h2>
            <p className="text-2xl mt-2">10</p>
          </div>
          <div className="text-4xl text-gray-300">
            <i className="fas fa-users"></i>
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
          <div>
            <h2 className="text-lg font-bold">Total Products</h2>
            <p className="text-2xl mt-2">567</p>
          </div>
          <div className="text-4xl text-gray-300">
            <i className="fas fa-box"></i>
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
          <div>
            <h2 className="text-lg font-bold">Total Recommendation</h2>
            <p className="text-2xl mt-2">789</p>
          </div>
          <div className="text-4xl text-gray-300">
            <i className="fas fa-shopping-cart"></i>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
          <div>
            <h2 className="text-lg font-bold">Total Input Token</h2>
            <p className="text-2xl mt-2">789</p>
          </div>
          <div className="text-4xl text-gray-300">
            <i className="fas fa-shopping-cart"></i>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
          <div>
            <h2 className="text-lg font-bold">Total Output Token</h2>
            <p className="text-2xl mt-2">789</p>
          </div>
          <div className="text-4xl text-gray-300">
            <i className="fas fa-shopping-cart"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
