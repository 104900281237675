// src/components/CustomerDashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomerDetail from './CustomerDetail';
import NewCustomerForm from './NewCustomerForm';


const CustomerCard = ({ customer, onClick }) => (
    <div className="bg-white p-6 rounded-lg shadow-md mb-4 flex justify-between items-center">
      <div>
        <h2 className="text-lg font-bold mb-1">{customer.name}</h2>
        <p className="text-gray-700 mb-1"><strong>ID:</strong> {customer.id}</p>
        <p className="text-gray-700 mb-1">{customer.platform}</p>
        <a href={customer.url} className="text-blue-500 hover:underline mb-1" target="_blank" rel="noopener noreferrer">
          {customer.url}
        </a>
        <p className="text-gray-700">{customer.language}</p>
      </div>
      <button onClick={() => onClick(customer)} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        View
      </button>
    </div>
  );
  
  
const CustomerDashboard = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [creatingCustomer, setCreatingCustomer] = useState(false);
    const admintoken = sessionStorage.getItem('adminAuthToken');

    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const response = await axios.get('https://catalog.api.generativecrm.com/api/customer', {
            headers: {
              'Authorization': `Bearer ${admintoken}`,
              'accept': '*/*'
            }
          });
          setCustomers(response.data);
        } catch (error) {
          console.error('Error fetching customers:', error);
        }
      };
  
      fetchCustomers();
    }, []);
  
    const handleCustomerClick = (customer) => {
      setSelectedCustomer(customer);
    };
  
    const handleBackClick = () => {
      setSelectedCustomer(null);
      setCreatingCustomer(false);
    };
  
    const handleCreateNewClick = () => {
      setCreatingCustomer(true);
      setSelectedCustomer(null);
    };
  
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        {selectedCustomer ? (
          <CustomerDetail customer={selectedCustomer} onBack={handleBackClick} />
        ) : creatingCustomer ? (
          <NewCustomerForm onBack={handleBackClick} />
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-6">Customers</h1>
            <div className="bg-white p-6 rounded-lg shadow-md mb-4 flex justify-between items-center">
              <button onClick={handleCreateNewClick} className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">+ Add New</button>
              <button className="bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300">Search</button>
            </div>
            {customers.map(customer => (
              <CustomerCard key={customer.id} customer={customer} onClick={handleCustomerClick} />
            ))}
          </>
        )}
      </div>
    );
  };
  
  export default CustomerDashboard;