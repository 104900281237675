import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaComments, FaHistory, FaPlus, FaSignOutAlt } from 'react-icons/fa';
import { IoChatbubbleEllipsesOutline, IoSendOutline } from 'react-icons/io5';
import { LuLayoutDashboard } from 'react-icons/lu';
import { FaPeopleGroup } from 'react-icons/fa6';
import { SiSimpleanalytics } from 'react-icons/si';
import { TbTargetArrow } from 'react-icons/tb';
import { GrCatalogOption } from 'react-icons/gr';
import { CiClock1, CiSquarePlus, CiSettings } from 'react-icons/ci';
import { MdOutlineFileUpload } from 'react-icons/md';
import '../App.css';
import Logo from '../components/Logo';
import { PiPlugsConnectedBold } from "react-icons/pi";
import { TfiControlStop } from "react-icons/tfi";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { FiChevronLeft } from 'react-icons/fi';
import { LuSheet } from "react-icons/lu";
import AdminDashboard from '../components/Admin';
import CustomerDashboard from '../components/CustomerDashboard';
import Catelog from '../components/Catelog';
import CsvEditor from '../components/CSVEditor';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const INTERNAL_AGENT_URL = process.env.REACT_APP_INTERNAL_AGENT_URL;

console.log(INTERNAL_AGENT_URL)

const PromptsGallery = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(null);

  

  useEffect(() => {
    
    const fetchPrompts = async () => {
      try {
        
        const response = await axios.get(`${INTERNAL_AGENT_URL}/prompts`);
        if (response.data) {
          setPrompts(response.data);
        } else {
          throw new Error('Unexpected data structure');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching prompts:', err);
        setError('Failed to fetch prompts: ' + err.message);
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);

  const formatPromptValue = (value) => {
    if (typeof value === 'string') {
      return value.replace(/\\n/g, '\n').replace(/`/g, '\\`');
    } else if (typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const processMarkdown = (content) => {
    const lines = content.split('\n');
    let inCodeBlock = false;
    
    const processedLines = lines.map(line => {
      if (line.trim().startsWith('```')) {
        inCodeBlock = !inCodeBlock;
        return line;
      }
      
      if (inCodeBlock) {
        return line;
      }

      if (line.startsWith('#')) {
        return line;
      }

      return line.trim() ? line : '';
    });

    return processedLines.join('\n');
  };

  const handlePromptChange = (e) => {
    setEditedPrompt({
      ...editedPrompt,
      value: e.target.value
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedPrompt({...prompts[selectedPrompt]});
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`${INTERNAL_AGENT_URL}/prompts`, {
        name: editedPrompt.key,
        value: editedPrompt.value
      });
      
      if (response.data) {
        console.log(response.data, "response.data in handleEdit")
        setPrompts(response.data);
        setIsEditing(false);

      } else {
        throw new Error('Unexpected data structure');
      }

    } catch (err) {
      console.error('Error saving prompt:', err);
      setError('Failed to save prompt: ' + (err.response?.data?.message || err.message));
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedPrompt(null);
  };

  if (loading) return <div className="p-4">Loading prompts...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;
  if (prompts.length === 0) return <div className="p-4">No prompts available</div>;

  return (
    <div className="flex h-full">
    <div className="w-1/4 bg-gray-100 p-4 overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Prompts</h2>
      {prompts.map((prompt, index) => (
        <div 
          key={index}
          className={`cursor-pointer p-2 mb-2 rounded ${selectedPrompt === index ? 'bg-blue-200' : 'hover:bg-gray-200'}`}
          onClick={() => setSelectedPrompt(index)}
        >
          {prompt.key}
        </div>
      ))}
    </div>
    <div className="w-3/4 p-4 overflow-y-auto">
      {selectedPrompt !== null && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{prompts[selectedPrompt].key}</h2>
            {!isEditing && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleEdit}
              >
                Edit
              </button>
            )}
          </div>
          {isEditing ? (
            <div>
              <textarea
                className="w-full h-64 p-2 border rounded"
                value={editedPrompt.value}
                onChange={handlePromptChange}
              />
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white p-6 rounded shadow">
              <ReactMarkdown
                children={prompts[selectedPrompt].value}
                components={{
                  h1: ({node, ...props}) => <h1 className="text-2xl font-bold my-4" {...props} />,
                  h2: ({node, ...props}) => <h2 className="text-xl font-semibold my-3" {...props} />,
                  h3: ({node, ...props}) => <h3 className="text-lg font-medium my-2" {...props} />,
                  p: ({node, ...props}) => <p className="my-2" {...props} />,
                  ul: ({node, ...props}) => <ul className="list-disc pl-5 my-2" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal pl-5 my-2" {...props} />,
                  li: ({node, ...props}) => <li className="my-1" {...props} />,
                  code: ({node, inline, ...props}) => 
                    inline ? <code className="bg-gray-100 rounded px-1" {...props} /> : <pre className="bg-gray-100 p-2 rounded my-2 overflow-x-auto" {...props} />,
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  </div>
  );
};

const Sidebar = ({
  isOpenDashboard,
  toggleOpenDashboard,
  isOpenCustomerDashboard,
  toggleOpenCustomerDashboard,
  isOpenCatalog,
  toggleOpenCatalog,
  isOpenTaxonomy,
  toggleOpenTaxonomy,
  isOpenPromptsGallery,
  toggleOpenPromptsGallery
}) => (
  <div className="sidebar sticky top-0 flex flex-col h-screen bg-white shadow-lg z-10 overflow-y-auto"
       style={{ transform: 'scale(var(--scale-factor, 1))', transformOrigin: 'top left' }}>
    <Logo />

    <button 
      className="flex items-center p-2 mx-4 my-2 text-sm font-normal text-left text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none focus:font-semibold"
      onClick={toggleOpenDashboard}
    > 
      <LuLayoutDashboard className="mr-2 text-xl text-black" />
      <span className="text-lg">Dashboard</span>
    </button>

    <button 
      onClick={toggleOpenCustomerDashboard}
      className="flex items-center p-2 mx-4 my-2 text-sm font-normal text-left text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none focus:font-semibold"
    >
      <GrCatalogOption className="mr-2 text-xl text-black" />
      <span className="text-lg">Customers</span>
    </button>

    <button 
      onClick={toggleOpenCatalog}
      className="flex items-center p-2 mx-4 my-2 text-sm font-normal text-left text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none focus:font-semibold"
    >
      <GrCatalogOption className="mr-2 text-xl text-black" />
      <span className="text-lg">Fashion Vision</span>
    </button>

    <button 
      onClick={toggleOpenTaxonomy}
      className="flex items-center p-2 mx-4 my-2 text-sm font-normal text-left text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none focus:font-semibold"
    >
      <LuSheet className="mr-2 text-xl text-black" />
      <span className="text-lg">Taxonomy</span>
    </button>

    <button 
      onClick={toggleOpenPromptsGallery}
      className="flex items-center p-2 mx-4 my-2 text-sm font-normal text-left text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none focus:font-semibold"
    >
      <FaComments className="mr-2 text-xl text-black" />
      <span className="text-lg">Prompts Gallery</span>
    </button>

    <div className="mt-auto py-4 border-t border-gray-200 pt-2">
      <button className="flex items-center justify-between w-full px-4 py-1 mb-1 text-sm font-normal text-left text-gray-700 hover:bg-gray-100 focus:outline-none focus:font-semibold">
        <div className="flex items-center">
          <span className="text-sm font-medium mx-2">Settings</span>
        </div>
        <CiSettings className="text-xl text-black" />
      </button>
      <button className="flex items-center justify-between w-full px-4 py-1 text-sm font-normal text-left text-gray-700 hover:bg-gray-100 focus:outline-none focus:font-semibold">
        <div className="flex items-center">
          <span className="text-sm font-medium mx-2">Logout</span>
        </div>
        <FaSignOutAlt className="text-xl text-black" />
      </button>
    </div>
  </div>
);

// MainPage component
const MainPage = () => {
  const [isOpenDashboard, setIsOpenDashboard] = useState(true);
  const [isOpenCustomerDashboard, setIsOpenCustomerDashboard] = useState(false);
  const [isOpenCatalog, setIsOpenCatalog] = useState(false);
  const [isOpenTaxonomy, setIsOpenTaxonomy] = useState(false);
  const [isOpenPromptsGallery, setIsOpenPromptsGallery] = useState(false);

  const toggleOpenDashboard = () => {
    setIsOpenDashboard(!isOpenDashboard);
    setIsOpenCustomerDashboard(false);
    setIsOpenCatalog(false);
    setIsOpenTaxonomy(false);
    setIsOpenPromptsGallery(false);
  };

  const toggleOpenCustomerDashboard = () => {
    setIsOpenDashboard(false);
    setIsOpenCustomerDashboard(!isOpenCustomerDashboard);
    setIsOpenCatalog(false);
    setIsOpenTaxonomy(false);
    setIsOpenPromptsGallery(false);
  };

  const toggleOpenCatalog = () => {
    setIsOpenDashboard(false);
    setIsOpenCustomerDashboard(false);
    setIsOpenCatalog(!isOpenCatalog);
    setIsOpenTaxonomy(false);
    setIsOpenPromptsGallery(false);
  };

  const toggleOpenTaxonomy = () => {
    setIsOpenDashboard(false);
    setIsOpenCustomerDashboard(false);
    setIsOpenCatalog(false);
    setIsOpenTaxonomy(!isOpenTaxonomy);
    setIsOpenPromptsGallery(false);
  };

  const toggleOpenPromptsGallery = () => {
    setIsOpenDashboard(false);
    setIsOpenCustomerDashboard(false);
    setIsOpenCatalog(false);
    setIsOpenTaxonomy(false);
    setIsOpenPromptsGallery(!isOpenPromptsGallery);
  };

  return (
    <div className="flex">
      <Sidebar
        isOpenDashboard={isOpenDashboard}
        toggleOpenDashboard={toggleOpenDashboard}
        isOpenCustomerDashboard={isOpenCustomerDashboard}
        toggleOpenCustomerDashboard={toggleOpenCustomerDashboard}
        isOpenCatalog={isOpenCatalog}
        toggleOpenCatalog={toggleOpenCatalog}
        isOpenTaxonomy={isOpenTaxonomy}
        toggleOpenTaxonomy={toggleOpenTaxonomy}
        isOpenPromptsGallery={isOpenPromptsGallery}
        toggleOpenPromptsGallery={toggleOpenPromptsGallery}
      />

      <div className={`transition-transform duration-300 flex-grow`}>
        <div className="flex flex-col">
          {isOpenDashboard && <AdminDashboard />}
          {isOpenCustomerDashboard && <CustomerDashboard />}
          {isOpenCatalog && <Catelog />}
          {isOpenTaxonomy && <CsvEditor />}
          {isOpenPromptsGallery && <PromptsGallery />}
        </div>
      </div>
    </div>
  );
}

export default MainPage;