import React, { useState } from 'react';
import axios from 'axios';

const NewCustomerForm = ({ onBack }) => {
  const [formData, setFormData] = useState({
    name: '',
    platform: '',
    url: '',
    language: '',
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/customer', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      onBack();
    } catch (error) {
      console.error('Error creating customer:', error);
    }
  };

  return (
    <div className="p-8 bg-white min-h-screen">
      <button className="text-blue-500 mb-4" onClick={onBack}>
        &larr; Back to Customers
      </button>
      <div>
        <h2 className="text-3xl font-bold mb-4">Create New Customer</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Platform</label>
            <input
              type="text"
              name="platform"
              value={formData.platform}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">URL</label>
            <input
              type="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Language</label>
            <input
              type="text"
              name="language"
              value={formData.language}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCustomerForm;