import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../App.css'; // Import the custom stylesheet

const ProductDetail = ({ product, onBack }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchProductDetails = async () => {
    const cliente_id = sessionStorage.getItem('userId');

    try {
      const response = await axios.get('https://vision.api.generativecrm.com/get_content_json/', {
        params: {
          cliente_id: cliente_id,
          product_id: product.productId,
        },
      });

      console.log(response.data);
      setProductDetails(response.data.vision_output);
      setImageUrls(response.data.image_urls);

    } catch (error) {
      if (error.response) {
        console.error('Error Response:', error.response.data);
      } else {
        console.error('Error:', error.message);
      }
    }
  };

  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    console.log(userId);
    fetchProductDetails(userId, product.productId);
  }, [product.productId]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  const renderFeatures = (features) => {
    return features.map((feature, index) => {
      const key = Object.keys(feature)[0];
      const value = feature[key];
      return (
        <div key={index} className="bg-gray-100 p-4 rounded-md inline-block max-w-xs">
          <h4 className="text-sm font-medium text-gray-700">{key}</h4>
          {Array.isArray(value) ? (
            <ul className="text-sm text-pink-500">
              {value.map((val, idx) => (
                <li key={idx}>{`${idx + 1}. ${val}`}</li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-pink-500">{value}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className="p-8 bg-white min-h-screen flex">
      <div className="w-1/3 sticky top-8 self-start">
        <button className="text-blue-500 mb-4" onClick={onBack}>
          &larr; Back to Catalog
        </button>
        <div className="slider-container mx-auto" style={{ width: '400px', height: '600px' }}>
          <Slider {...settings}>
            {imageUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Product image ${index + 1}`} className="w-full h-full object-contain" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="w-2/3 pl-8">
        <h2 className="text-2xl font-bold mb-4">Product Details</h2>
        <p className="mb-8">View and manage the details of the selected product.</p>
        <h3 className="text-2xl font-semibold mb-4">{product.name}</h3>
        {Object.keys(productDetails).map((section, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-lg font-semibold mb-4">{section}</h3>
            <div className="grid grid-cols-2 gap-4">
              {renderFeatures(productDetails[section])}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetail;
