import React from 'react';


const CustomerDetail = ({ customer, onBack }) => {
    return (
      <div className="p-8 bg-white min-h-screen">
        <button className="text-blue-500 mb-4" onClick={onBack}>
          &larr; Back to Customers
        </button>
        <div>
          <h2 className="text-3xl font-bold mb-4">{customer.name}</h2>
          <p><strong>Platform:</strong> {customer.platform}</p>
          <p><strong>Language:</strong> {customer.language}</p>
          <p>
            <strong>URL:</strong> <a href={customer.url} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">{customer.url}</a>
          </p>
          <p className="mt-4"><strong>Categories:</strong></p>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            {customer.categories.split(',').filter(Boolean).map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul>
          <p><strong>Fetched Products:</strong> {customer.fetchedProducts ? 'Yes' : 'No'}</p>
          <p><strong>Taxonomy Version:</strong> {customer.taxonomyVersion}</p>
        </div>
      </div>
    );
  };
  
  export default CustomerDetail;