import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductDetail from './ProductDetail';

const FilterComponent = () => {
  const [showCatalogFilters, setShowCatalogFilters] = useState(false);
  const [showFashionFilters, setShowFashionFilters] = useState(false);
  const [catalogFilters, setCatalogFilters] = useState({
    colors: [],
    sizes: [],
    availability: [],
  });
  const [fashionFilters, setFashionFilters] = useState([]);

  useEffect(() => {
    const catalogFiltersData = {
      colors: ['Red', 'Blue', 'Green', 'Black', 'White'],
      sizes: ['XS', 'S', 'M', 'L', 'XL'],
      availability: ['In Stock', 'Out of Stock'],
    };

    const fashionFiltersData = [
      { type: 'Silhouette', values: ['Fitted', 'Loose', 'A-line'] },
      { type: 'Fit', values: ['Slim', 'Regular', 'Relaxed'] },
      { type: 'Neckline', values: ['High Neck', 'V-Neck', 'Round Neck'] },
      { type: 'Occasion', values: ['Casual', 'Formal', 'Party'] },
    ];

    setCatalogFilters(catalogFiltersData);
    setFashionFilters(fashionFiltersData);
  }, []);

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-6">Filters</h2>
      <input
        type="text"
        placeholder="Type on your own words What are you looking for"
        className="w-full p-2 mb-6 border rounded-lg"
      />
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Catalog Filters</h3>
          <button
            className="text-gray-500 focus:outline-none"
            onClick={() => {
              setShowCatalogFilters(!showCatalogFilters);
              setShowFashionFilters(false);
            }}
          >
            {showCatalogFilters ? '-' : '+'}
          </button>
        </div>
        <div
          className={`transition-height duration-1000 ease-in-out overflow-hidden ${showCatalogFilters ? 'h-auto' : 'h-0'}`}
          style={{ transitionProperty: 'height' }}
        >
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Price Range</label>
            <div className="flex space-x-4">
              <input
                type="number"
                placeholder="Min"
                className="w-1/2 p-2 border rounded-lg"
              />
              <input
                type="number"
                placeholder="Max"
                className="w-1/2 p-2 border rounded-lg"
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Inventory Availability</label>
            <select className="w-full p-2 border bg-white rounded-lg">
              <option>Select availability</option>
              {catalogFilters.availability.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Sizes</label>
            <div className="grid grid-cols-3 gap-4">
              {catalogFilters.sizes.map((size) => (
                <label key={size} className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox h-4 w-4" />
                  <span className="ml-2">{size}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Colors</label>
            <div className="grid grid-cols-3 gap-4">
              {catalogFilters.colors.map((color) => (
                <label key={color} className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox h-4 w-4" />
                  <span className="ml-2">{color}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t pt-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Fashion Filters</h3>
          <button
            className="text-gray-500 focus:outline-none"
            onClick={() => {
              setShowFashionFilters(!showFashionFilters);
              setShowCatalogFilters(false);
            }}
          >
            {showFashionFilters ? '-' : '+'}
          </button>
        </div>
        <div
          className={`transition-height duration-1000 ease-in-out overflow-hidden ${showFashionFilters ? 'h-auto' : 'h-0'}`}
          style={{ transitionProperty: 'height' }}
        >
          <div className="max-h-96 overflow-y-auto fashion-scroll">
            {fashionFilters.map((filter, index) => (
              <div className="mb-6" key={index}>
                <label className="block text-sm font-medium text-gray-700 mb-2">{filter.type}</label>
                <select className="w-full p-2 border rounded-lg bg-white">
                  <option>Select {filter.type.toLowerCase()}</option>
                  {filter.values.map((value, index) => (
                    <option key={index} value={value}>{value}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Catelog = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const productsPerPage = 8;

  const token = sessionStorage.getItem('authToken');
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://catalog.api.generativecrm.com/api/products', {
          params: {
            page: currentPage,
            limit: productsPerPage
          },
          headers: {
            'Authorization': `Bearer ${token}`,
            'accept': '*/*'
          }
        });
        console.log(response.data);
        setProducts(response.data.items);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [currentPage]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleBackClick = () => {
    setSelectedProduct(null);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const pageNumbers = [];
    const ellipsis = '...';

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === 2 || i === totalPages || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={`px-3 py-1 ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {i}
          </button>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push(
          <span key={i} className="px-3 py-1 text-gray-400">
            {ellipsis}
          </span>
        );
      }
    }

    return (
      <div className="flex flex-wrap justify-center items-center space-x-1">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-200 rounded"
        >
          Previous
        </button>
        {pageNumbers}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-200 rounded"
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="">
      {selectedProduct ? (
        <ProductDetail product={selectedProduct} onBack={handleBackClick} />
      ) : (
        <div className="bg-white pt-8 px-8 pb-4">
          <div className="flex">
            <div className="w-1/4 sticky top-0">
              <h1 className="text-3xl font-bold mb-6">Fashion Vision</h1>
              <p className="mb-6">Select and filter products available in the catalog.</p>
              <FilterComponent />
            </div>
            <div className="w-3/4 pl-8">
              <div className="grid grid-cols-4 gap-1">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="bg-white p-2 m-1 rounded shadow-md cursor-pointer"
                    onClick={() => handleProductClick(product)}
                  >
                    <div className="h-56 bg-gray-200 mb-4 flex items-center justify-center">
                      {product.image1 ? (
                        <img src={product.image1} alt={product.name} className="h-full w-full object-cover" />
                      ) : (
                        <div className="h-full w-full flex items-center justify-center text-gray-500">No Image</div>
                      )}
                    </div>
                    <h3 className="text-l font-semibold mb-2 truncate">{product.name}</h3>
                    <p className="text-gray-600 mb-2">Limited supply</p>
                    <p className="text-gray-900 font-bold mb-4">${product.price}</p>
                    <a href="#" className="text-pink-500">See fashion details</a>
                  </div>
                ))}
              </div>
              <div className="mt-4 flex justify-end">
                <button className="bg-blue-500 text-white px-4 py-2 rounded mr-5">Export</button>
                <button className="bg-gray-200 text-black px-4 py-2 rounded mr-10">Vtex Description</button>
                {renderPagination()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Catelog;
