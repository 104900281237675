// src/components/Login.js
import React, { useState } from 'react';
import Modal from '../components/HomePageModal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import axios from 'axios';


const CATALOG_API = process.env.REACT_APP_CATALOG_API_DEV;


console.log(CATALOG_API)

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    if (email !== "admin@example.com") {
      setError('Authentication error: You are not authorized to access this system.');
      return;
    }
    try {

      console.log(JSON.stringify({
        "email": email,
        "password": password
      }))

      console.log(`${CATALOG_API}/api/v1/auth/email/login`)
      const response = await fetch(`${CATALOG_API}/api/v1/auth/email/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": email,
          "password": password,
        }),
      });

      

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        // Store the token and user ID in session storage
        sessionStorage.setItem('adminAuthToken', data.token);
        sessionStorage.setItem('userId', data.user.id);

        // Redirect to the main page
        navigate('/main-page');
      } else {
        // Display error message
        setError('Algo deu errado! Confira sua senha e usuário e tente novamente.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Algo deu errado! Confira sua senha e usuário e tente novamente.');
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-col justify-center w-full max-w-md px-4 mx-auto sm:w-1/2">
        <div className="mb-8 text-center">
          <h1 className="text-4xl font-bold">Fashion.ai</h1>
          <p className="mt-2 text-sm text-gray-600">
            Transformando a indústria da moda com inteligência artificial, criando tendências e otimizando sua experiência de compra. Seja parte do futuro da moda!
          </p>
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">
              Email
            </label>
            <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">
              Senha
            </label>
            <input
              className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <div className="mb-4 text-red-500">
              {error}
            </div>
          )}
          <div className="mb-4 flex items-center justify-between">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
              />
              <span className="ml-2 text-sm text-gray-600">Lembrar de mim</span>
            </label>
            <a className="inline-block text-sm font-bold text-blue-500 align-baseline hover:text-blue-800" href="#">
              Esqueci minha senha
            </a>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Fazer login
            </button>
          </div>
        </form>
      </div>
      <div className="relative hidden w-1/2 bg-cover lg:block" style={{ backgroundImage: `url('/homepage_bg2.jpg')` }}>
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-50">
          <Modal />
        </div>
      </div>
    </div>
  );
}

export default Login;
